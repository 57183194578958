import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ChangePassword from './components/Auth/ChangePassword';
import Dashboard from './components/Dashboard/Dashboard';
import NotFound from './pages/NotFound';
import Navbar from './components/Navbar';
// import CustomerList from './components/Customers/CustomerList';
import AddCustomer from './components/Customers/AddCustomer';
import EditCustomer from './components/Customers/EditCustomer';
import Customers from './components/Customers/Customers';

import LaporanList from './components/LaporanPemasukanBahanBaku/LaporanList';
import EditLaporan from './components/LaporanPemasukanBahanBaku/EditLaporan';
import LaporanList2 from './components/LaporanPemakaianBahanBaku/LaporanPemakaianList';
import LaporanPemakaianEdit from './components/LaporanPemakaianBahanBaku/LaporanPemakaianEdit';
import LaporanPemasukanHasilProduksiList from './components/LaporanPemasukanHasilProduksi/LaporanPemasukanHasilProduksiList';
import EditLaporanPemasukanHasilProduksi from './components/LaporanPemasukanHasilProduksi/EditLaporanPemasukanHasilProduksi';

import LaporanPengeluaranHasilProduksiList from './components/LaporanPengeluaranHasilProduksi/LaporanPengeluaranHasilProduksiList';
import EditLaporanPengeluaranHasilProduksi from './components/LaporanPengeluaranHasilProduksi/EditLaporanPengeluaranHasilProduksi';

import LaporanMutasiBahanBakuList from './components/LaporanMutasiBahanBaku/LaporanMutasiBahanBakuList';
import LaporanMutasiBahanBakuEdit from './components/LaporanMutasiBahanBaku/LaporanMutasiBahanBakuEdit';

import LaporanMutasiHasilProduksiList from './components/LaporanMutasiHasilProduksi/LaporanMutasiHasilProduksiList';
import LaporanMutasiHasilProduksiEdit from './components/LaporanMutasiHasilProduksi/LaporanMutasiHasilProduksiEdit';


function App() {
  return (
    <Router>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/add" element={<AddCustomer />} />
          <Route path="/customers/edit/:id" element={<EditCustomer />} />

          <Route path="/laporan-pemasukan-bahan-baku" element={<LaporanList />} />
          <Route path="/edit/:uuid" element={<EditLaporan />} />

          <Route path="/laporan-pemakaian-bahan-baku" element={<LaporanList2 />} />
          <Route path="/laporan-pemakaian-bahan-baku/edit/:id" element={<LaporanPemakaianEdit />} />

          <Route path="/laporan-pemasukan-hasil-produksi" element={<LaporanPemasukanHasilProduksiList />} />
          <Route path="/edit-laporan-pemasukan-hasil-produksi/:id" element={<EditLaporanPemasukanHasilProduksi />} />

          <Route path="/laporan-pengeluaran-hasil-produksi" element={<LaporanPengeluaranHasilProduksiList />} />
          <Route path="/edit-laporan-pengeluaran-hasil-produksi/:id" element={<EditLaporanPengeluaranHasilProduksi />} />

          <Route path="/laporan-mutasi-bahan-baku" element={<LaporanMutasiBahanBakuList />} />
          <Route path="/laporan-mutasi-bahan-baku/edit/:id" element={<LaporanMutasiBahanBakuEdit />} />
          
          <Route path="/laporan-mutasi-hasil-produksi" element={<LaporanMutasiHasilProduksiList />} />
          <Route path="/laporan-mutasi-hasil-produksi/edit/:id" element={<LaporanMutasiHasilProduksiEdit />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
