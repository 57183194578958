import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLaporan, deleteLaporan } from '../../api/laporanPemasukanBahanBakuService';
import * as XLSX from 'xlsx';
import { Table, Button, Pagination } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';

const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
};

const LaporanList = () => {
    const [laporan, setLaporan] = useState([]);
    const [filteredLaporan, setFilteredLaporan] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [tempStartDate, setTempStartDate] = useState('');
    const [tempEndDate, setTempEndDate] = useState('');
    const [filtered, setFiltered] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [sortField, setSortField] = useState('tanggalRekam');
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();

    useEffect(() => {
        fetchLaporan();
    }, [currentPage, sortField, sortOrder]);

    useEffect(() => {
        applyFilter();
    }, [tempStartDate, tempEndDate, laporan]);

    const fetchLaporan = async () => {
        try {
            const filters = {};
            if (startDate) filters.startDate = startDate;
            if (endDate) filters.endDate = endDate;

            const response = await getLaporan(filters);
            if (Array.isArray(response)) {
                setLaporan(response);
            } else {
                console.error("Expected an array but got:", typeof response);
                setLaporan([]);
                setFilteredLaporan([]);
            }
        } catch (error) {
            console.error("Error fetching laporan:", error);
            setLaporan([]);
            setFilteredLaporan([]);
        }
    };

    const applyFilter = () => {
        let filteredData = laporan;
        if (tempStartDate) {
            filteredData = filteredData.filter(item => new Date(item.pabeanTanggal) >= new Date(tempStartDate));
        }
        if (tempEndDate) {
            filteredData = filteredData.filter(item => new Date(item.pabeanTanggal) <= new Date(tempEndDate));
        }
        setFilteredLaporan(filteredData);
    };

    const handleFilter = () => {
        // Set start and end dates based on temporary filter values
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);
        // Trigger re-filtering
        setFiltered(true);
    };

    const handleReset = () => {
        setStartDate('');
        setEndDate('');
        setTempStartDate('');
        setTempEndDate('');
        setFiltered(false);
        fetchLaporan(); // Fetch the data again to reset to the initial state
    };

    const handleDelete = async (id) => {
        try {
            await deleteLaporan(id);
            fetchLaporan();
        } catch (error) {
            console.error("Error deleting laporan:", error);
        }
    };

    const handleEdit = (uuid) => {
        navigate(`/edit/${uuid}`);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            filteredLaporan.map((laporan, index) => ({
                No: index + 1,
                TanggalRekam: formatDate(laporan.tanggalRekam),
                JenisDokumen: laporan.jenisDokumen,
                DokumenNomor: laporan.dokumenNomor,
                PabeanTanggal: formatDate(laporan.pabeanTanggal),
                KodeHS: laporan.kodeHS,
                PabeanNomorSeriBarang: laporan.pabeanNomorSeriBarang,
                BuktiPenerimaanBarangNomor: laporan.buktiPenerimaanBarangNomor,
                BuktiPenerimaanBarangTanggal: formatDate(laporan.buktiPenerimaanBarangTanggal),
                KodeBarang: laporan.kodeBarang,
                NamaBarang: laporan.namaBarang,
                Satuan: laporan.satuan,
                Jumlah: laporan.jumlah,
                MataUang: laporan.mataUang,
                NilaiBarang: laporan.nilaiBarang,
                Gudang: laporan.gudang,
                NegaraAsalBarang: laporan.negaraAsalBarang,
            }))
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Laporan Pemasukan Bahan Baku');
        XLSX.writeFile(workbook, 'laporan_pemasukan_bahan_baku.xlsx');
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const getSortedData = () => {
        const sortedData = [...filteredLaporan].sort((a, b) => {
            if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
        return sortedData;
    };

    const getPagedData = () => {
        const sortedData = getSortedData();
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return sortedData.slice(startIndex, endIndex);
    };

    const totalPages = Math.ceil(filteredLaporan.length / itemsPerPage);

    return (
        <div>
            <h2>Laporan Pemasukan Bahan Baku</h2>

            <div className="mb-3">
                <label htmlFor="startDate" className="form-label">Start Date</label>
                <input
                    type="date"
                    id="startDate"
                    value={tempStartDate}
                    onChange={(e) => setTempStartDate(e.target.value)}
                    className="form-control"
                />
            </div>
            
            <div className="mb-3">
                <label htmlFor="endDate" className="form-label">End Date</label>
                <input
                    type="date"
                    id="endDate"
                    value={tempEndDate}
                    onChange={(e) => setTempEndDate(e.target.value)}
                    className="form-control"
                />
            </div>
            
            <Button onClick={handleFilter} className="btn btn-primary">Filter</Button>
            <Button onClick={handleReset} className="btn btn-secondary ms-2">Reset Filter</Button>
            {filtered && (
                <Button onClick={exportToExcel} className="btn btn-success ms-2">Export to Excel</Button>
            )}

            {filtered && (
                <>
                    <div className="table-responsive mt-3">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th onClick={() => handleSort('tanggalRekam')}>
                                        Tanggal Rekam
                                        {sortField === 'tanggalRekam' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('jenisDokumen')}>
                                        Jenis Dokumen
                                        {sortField === 'jenisDokumen' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('dokumenNomor')}>
                                        Dokumen Nomor
                                        {sortField === 'dokumenNomor' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('pabeanTanggal')}>
                                        Pabean Tanggal
                                        {sortField === 'pabeanTanggal' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('kodeHS')}>
                                        Kode HS
                                        {sortField === 'kodeHS' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('pabeanNomorSeriBarang')}>
                                        Pabean Nomor Seri Barang
                                        {sortField === 'pabeanNomorSeriBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('buktiPenerimaanBarangNomor')}>
                                        Bukti Penerimaan Barang Nomor
                                        {sortField === 'buktiPenerimaanBarangNomor' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('buktiPenerimaanBarangTanggal')}>
                                        Bukti Penerimaan Barang Tanggal
                                        {sortField === 'buktiPenerimaanBarangTanggal' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('kodeBarang')}>
                                        Kode Barang
                                        {sortField === 'kodeBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('namaBarang')}>
                                        Nama Barang
                                        {sortField === 'namaBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('satuan')}>
                                        Satuan
                                        {sortField === 'satuan' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('jumlah')}>
                                        Jumlah
                                        {sortField === 'jumlah' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('mataUang')}>
                                        Mata Uang
                                        {sortField === 'mataUang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('nilaiBarang')}>
                                        Nilai Barang
                                        {sortField === 'nilaiBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('gudang')}>
                                        Gudang
                                        {sortField === 'gudang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('negaraAsalBarang')}>
                                        Negara Asal Barang
                                        {sortField === 'negaraAsalBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getPagedData().map((laporan, index) => (
                                    <tr key={laporan.uuid}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td>{formatDate(laporan.tanggalRekam)}</td>
                                        <td>{laporan.jenisDokumen}</td>
                                        <td>{laporan.dokumenNomor}</td>
                                        <td>{formatDate(laporan.pabeanTanggal)}</td>
                                        <td>{laporan.kodeHS}</td>
                                        <td>{laporan.pabeanNomorSeriBarang}</td>
                                        <td>{laporan.buktiPenerimaanBarangNomor}</td>
                                        <td>{formatDate(laporan.buktiPenerimaanBarangTanggal)}</td>
                                        <td>{laporan.kodeBarang}</td>
                                        <td>{laporan.namaBarang}</td>
                                        <td>{laporan.satuan}</td>
                                        <td>{laporan.jumlah}</td>
                                        <td>{laporan.mataUang}</td>
                                        <td>{laporan.nilaiBarang}</td>
                                        <td>{laporan.gudang}</td>
                                        <td>{laporan.negaraAsalBarang}</td>
                                        <td>
                                            <Button onClick={() => handleEdit(laporan.uuid)} className="btn btn-warning w-100">Edit</Button>
                                            <Button onClick={() => handleDelete(laporan.uuid)} className="btn btn-danger w-100">Delete</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <Pagination className="mt-3">
                        <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Pagination.Item
                                key={i + 1}
                                active={i + 1 === currentPage}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} />
                    </Pagination>
                </>
            )}
        </div>
    );
};

export default LaporanList;
